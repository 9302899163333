.index h1 {
  margin: 0 0 0.5em -1px; /* misaligned left margin, super nitpicky */
  font-size: 1.925em;
  font-weight: 500;
  line-height: 1.2;
}

.index h2 {
  margin: 0.5em 0 0.5em -1px;
  font-size: 1.3em;
  font-weight: 400;
  line-height: 1.5;
}

.index p {
  margin: 0.85em 0;
  font-size: 1.05em;
  line-height: 1.7;
}

.index p:last-of-type {
  margin-bottom: 0;
}

.index sup {
  margin: 0 0.1em;
  font-size: 0.6em;
}

.wave {
  display: inline-block;
  margin-left: 0.1em;
  font-size: 1.2em;
}

@media (prefers-reduced-motion: no-preference) {
  .wave {
    animation: wave 5s ease 1s infinite;
    transform-origin: 65% 80%;
  }

  @keyframes wave {
    0% {
      transform: rotate(0deg);
    }
    5% {
      transform: rotate(14deg);
    }
    10% {
      transform: rotate(-8deg);
    }
    15% {
      transform: rotate(14deg);
    }
    20% {
      transform: rotate(-4deg);
    }
    25% {
      transform: rotate(10deg);
    }
    30% {
      transform: rotate(0deg);
    }

    /* pause for ~9 out of 10 seconds */
    100% {
      transform: rotate(0deg);
    }
  }
}

@media (max-width: 768px) {
  .index h1 {
    font-size: 1.8em;
  }

  .index h2 {
    font-size: 1.3em;
  }

  .index p {
    font-size: 1em;
    line-height: 1.9;
  }
}
